import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUi from "element-ui";
import "../element-variables.scss";
import "./sass/index.scss";
import "./sass/font.scss";
import i18n from './i18n'

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import scroll from "vue-seamless-scroll";
Vue.use(scroll);
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUi, { size: 'mini', i18n: (key, value) => i18n.t(key, value) })
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
