import Vue from 'vue'
import VueI18n from "vue-i18n";
import zh from './zh'
import en from './en'
import elementZh from 'element-ui/lib/locale/lang/zh-CN' // 简体中文
import elementEn from 'element-ui/lib/locale/lang/en'
Vue.use(VueI18n)
const messages = {
    zh: {
        ...zh,
        ...elementZh
    },
    en: {
        ...en,
        ...elementEn
    }
}
export function getLanguage() {
    const chooseLanguage = localStorage.getItem('language')
    if (chooseLanguage) return chooseLanguage
    const language = (navigator.language || navigator.browserLanguage).toLowerCase()
    const locales = Object.keys(messages)
    for (const locale of locales) {
      if (language.indexOf(locale) > -1) {
        localStorage.setItem('language',locale)
        return locale
      }
    }
    // return 'en'
    return 'en'
  }
const i18n = new VueI18n({
    // set locale
    // options: zh | th
    locale: getLanguage(),
    // set locale messages
    messages
})
export default i18n