export default {
    'home': {
        'home_page': 'Home Page',
        'about_rice_barn': 'About',
        'business_and_service': 'Service',
        'dynamic': 'News',
        'join_us': 'Join Us',
        'contact_us': 'Contact Us',
        'enterprise_overview': 'Overview',
        'branch_architecture': 'Branch',
        'main_business': 'Main business',
        'rice_industrial_platform': 'MRO',
        'rice_popcorn_platform': 'Popcorn',
        'rice_outside_platform': 'Miquan external integrated service platform',
        'rice_oversea_store': 'Overseas Warehouse',
        'rice_introduction': 'Introduction',
        'culture_and_vision': 'Culture & Vision',
        'social_responsibility': 'Social responsibility',
        'gz_airport_rice': 'Guangzhou Airport Goodchains Warehouse Supply Chain Co., Ltd.',
        'sc_ct': 'Sichuan Southeast Asia Development Co., Ltd. ',
        'sc_zf': 'Sichuan Zhongfa Industrial Products Technology Development Co., Ltd.',
        'gz_rice': 'Guangzhou Goodchains Warehouse Supply Chain Management Co., Ltd. ',
        'sc_rice': 'Sichuan Micang Nanxiang Supply Chain Management Co., Ltd.',
        'legal_statement': 'Legal Notices',
        'legal_statement_1': 'The GOODCHAINS SCM GROUP CO.,LTD website (hereinafter referred to as the "Website") is created by the GOODCHAINS SCM GROUP CO.,LTD. Anyone who accesses, browses and uses the Site acknowledges that he/she has read, understands and agrees to be bound by this statement and to comply with all applicable laws and regulations.',
        'copyright_notice': 'Copyright Notice',
        'copyright_notice_1': 'The entire contents of this website (https://www.goodchains.cn/) are owned by the copyright holder and are not hereby authorized in any way. The contents of this website are for informational purposes only, for personal or non-commercial use only, and may not be reproduced or published on any network computer or transmitted in any media. Without the permission of the copyright owner, no one may use it without authorization (including but not limited to: copying, distributing, displaying, mirroring, uploading, downloading in an unlawful manner). Otherwise, the copyright owner will pursue its legal responsibility according to the law.',
        'disclaimer': 'Disclaimer',
        'disclaimer_1': 'The copyright owner reserves the right to make changes to the content of this website at any time without prior notice. The copyright owner does not warrant or represent that the databases displayed on this website are accurate, comprehensive or up-to-date. All images on this website',
        'website_map': 'Site Map',
        'draw_about': 'About GOODCHAINS',
        'enterprise_profile': 'Company Profile',
        'branch': 'Arm',
        'draw_business_service': 'Operations & Services',
        'social_recruitment': 'Social Recruitment',
        'micang_recruitment_statement': 'GOODCHAINS SCM GROUP CO.,LTD Recruitment Statement',
        'address': 'Address',
        'mail': 'Mailbox',
        'phone': 'Contact number',
        'back': 'Return',
        'waybill_query': 'Waybill Inquiry',
        'micang_group': 'GOODCHAINS SCM GROUP CO.,LTD',
        'company_addr': `602, Zhongyang Business Building, No. 24, Fuhai Avenue, Bao'an District, Shenzhen, China`,
        'postcode': 'Postal code',
        'telephone': 'Telephones',
        'company_info': 'It is a comprehensive logistics enterprise with its own port as the core, integrating cross-border e-commerce, port customs clearance, ground handling, freight forwarding and supply chain management and other major business segments in one. Headquartered in Shenzhen, we have set up several branches in Shanghai, Guangzhou, Chengdu, Hong Kong, Thailand, Malaysia, etc....',
        'detailed_content': 'Details',
        'popcorn': 'Popcorn',
        'garment': 'IMD',
        'industrial_products': 'MRO',
        'overseas_storage': 'Overseas warehouse',
        'micang_group_text_1': 'GOODCHAINS SCM GROUP CO.,LTD is a comprehensive logistics service enterprise with cross-border logistics and trade as its core, integrating cross-border e-commerce, customs clearance at ports, ground handling, freight forwarding and supply chain management. The group is headquartered in Shenzhen, and has set up several branches in Shanghai, Guangzhou, Foshan, Chengdu, Hong Kong, Thailand, Malaysia, Myanmar, Germany and other places.',
        'micang_group_text_2': `In China, relying on its own port resources in South China and Southwest China, MICRON Supply Chain establishes a "multi-port, multi-channel, omni-directional" service system through market analysis and resource integration, and carries out a service model that takes into account the individualization of services and standardization of implementation, and provides standardized basic services, such as freight forwarding, customs clearance at the ports, ground handling, warehouse management, etc., while constantly approaching the needs of customers and deepening the service content. While providing standardized basic services such as freight forwarding, customs clearance at ports, ground handling, warehouse management, etc., the company constantly keeps close to customers' needs, deepens the service content, combines innovative concepts and information technology, designs personalized logistic solutions for customers, provides professional and highly efficient logistic services for customers' supply chain management, effectively reduces the risks of customers' supply chain, and helps the customers stand out from the crowd in the fierce competition in the market.`,
        'micang_group_text_3': `Tackling difficulties and moving forward, all the staff of MICRON adheres to the full enthusiasm, always bearing in mind the original intention at the beginning of the establishment and the mission on the way forward, seeking development with quality, winning affirmation with performance, and building MICRON Group into China's leading supply chain service enterprise step by step by continuous exploration.`,
        'corporate_culture': 'Company culture',
        'intellectual_management': 'Intelligent management',
        'meticulous_service': 'Intensive service',
        'grow_together': 'Grow up together',
        'gorporate_vision': 'Corporate Vision',
        'margin_mc_world_win': 'Gathering at GOODCHAINS, Winning with the World',
        'over_difficult_together': '2022, During the outbreak of Hong Kong epidemic, China-Hong Kong fleet assisted CCTC in transporting materials across the border News',
        'help_each_other': `Fighting the epidemic, you and I, we're on the same side, sticking to the defense line, watching out for each other.`,
        'view_count': 'Views',
        'help_each_other_text': 'Chengdu Municipal Bureau of Commerce urgently commissioned GOODCHAINS to purchase emergency supplies for epidemic prevention and control, and supply them to some medical and healthcare organizations in the epidemic-hit areas to fight against the epidemic together. MICRON Group gave full play to its own advantages, relying on its own supply chain resources, to quickly complete the resource integration and supply.',
        'help_each_other_text_2': 'On the afternoon of January 29, 2020, the first batch of supplies from Thailand arrived at Chengdu Shuangliu Airport from Bangkok, Thailand. 100,000 masks were provided to the urgently needed medical institutions through the Chengdu Municipal Government, and this batch of supplies was also the first batch of overseas purchased supplies in Southwest China. During the epidemic period, MICRO Group has purchased and supplied millions of medical supplies for governments and medical institutions around the world, and its high-quality and high-efficiency resource integration and logistics capability has been affirmed by all walks of life.',
        'gz_airport_text_1': 'Guangzhou Goodchains Warehouse Supply Chain Management Co., Ltd.was established in 2021 as a joint venture between Guangzhou Airport Industrial Investment Group Co. The company aims to make full use of the advantages of Guangzhou Airside Economic Demonstration Zone and Guangzhou Baiyun Airport Comprehensive Protection Zone, take the cross-border business as the traction, join hands with centralized state-owned enterprises, listed companies and industry leaders, and provide one-stop sourcing and marketing supply chain services by integrating the international logistics, import and export customs clearance, warehouse, cross-border transportation and other comprehensive services, covering raw materials, agricultural products, forestry and woodworking industries, industrial products, shipping materials, new materials, electronic products and foodstuffs, and actively build an autonomous and secure supply chain, and actively build an independent control and security system. The company is actively building an autonomous, controllable, safe and reliable supply chain system.',
        'mall_entrance': 'Mall Entrance',
        'sc_ct_text_1': `Ltd. is a state-owned joint venture with Chengdu Jinniu Guotou Real Estate Company Limited and Chengdu Jinniu Huancheng Investment Management Company Limited. The company's main business includes comprehensive foreign trade services, import and export customs clearance services, overseas warehouse services, international logistics services, etc., and set up a branch in Thailand, with ASEAN governments and enterprises in close contact, to further develop all kinds of import and export trade in commodities, trade in services and other businesses. Meanwhile, the company has set up an overseas warehouse in Bangkok, Thailand.`,
        'sc_ct_text_2': `Committed to bringing Chengdu's commodities to Southeast Asia and expanding in-depth cooperation between Chengdu and ASEAN countries in trade, logistics and other areas`,
        'sc_zf_text_1': `Ltd. is committed to building a professional one-stop procurement service platform for MRO industrial products. Under the concept of advocating "sunshine procurement, cost reduction and efficiency enhancement, big platform, big supervision", MICRO provides products and services to domestic and foreign large and medium-sized industrial enterprises through the platform, including the construction of intelligent supply chain system, product promotion, import and export trade, domestic and foreign logistics, supply chain finance, MRO repair and maintenance, spare parts procurement, warehouse and other services. We provide products and services to large and medium-sized industrial enterprises at home and abroad through the platform, including the construction of intelligent supply chain system, product promotion, import and export trade, domestic and overseas logistics, supply chain finance, MRO repair and maintenance, procurement of spare parts, warehouse and other services. Contribute to the development of industrial Internet, industrial big data and industrial technology.`,
        'gz_rice_text_1': 'Guangzhou Goodchains Warehouse was officially listed for business in March 2020, with the initial period mainly focusing on exporting anti-epidemic materials and warehouse business. starting from mid-May, it reached a strategic partnership with Apex International Freight Forwarding Co., Ltd. to act as an agent for its chartered flights business and 9610 and 0110 export business in mid-May.',
        'gz_rice_text_2': `Since the company started its business, it has gradually and successively established solid and long-lasting cooperative relationships with famous enterprises in the logistics industry, and its business has maintained rapid growth in general. Since entering 2021, under the premise of maintaining the steady development of the original business, we have been striving to seek for more partners, and the business scope continues to extend to the southwest and northwest of the country, and try to open up the market space in other regions of the country.`,
        'sc_rice_text_1': `Relying on the strong supply chain organization ability, foreign trade comprehensive service ability, own port resources and Southeast Asia logistics and trade resources of MICRON Group, Sichuan MICRON Southbound Supply Chain Management Co., Ltd. effectively integrates the characteristic industrial chain of Southwest China and South China, and carries out the construction of China-Laos Railway comprehensive service system, supply chain service, international trade financial system, etc, to build a comprehensive RCEP trade hub platform. To help Qingbaijiang and Chengdu build the most influential international trade hub in the western region, and to open a new era of China-Laos railroad transportation.`,
        'sc_rice_text_2': `Sichuan Micang Nanxiang Supply Chain Management Co., Ltd. provides import and export cargo logistics and transportation services (including general cargo and cold chain transportation) from all over the country - Qingbaijiang - Southeast Asia (Laos, Thailand, Myanmar, Vietnam, Malaysia), and provides customers with whole-border logistics solutions and implementation services, including railroad freight forwarding services and customs clearance within and outside the country, container leasing, inventory management, cold chain services, and so on.`,
        'mc_indu_mall': 'Yonekura Industrial Mall',
        'mc_indu_mall_1': 'Sichuan Zhongfa Industrial Products Technology Development Co., Ltd.  is a subsidiary of MICRON Group, which is committed to building a professional MRO industrial products one-stop procurement service platform, and is a state-owned large-scale high-tech enterprise with outstanding innovation ability by empowering enterprise development with Internet technology.',
        'mc_indu_mall_2': 'Micang MRO industrial products platform advocates the service concept of "sunshine procurement, cost reduction and efficiency enhancement, big platform, big supervision", and provides products and services to domestic and foreign large and medium-sized industrial enterprises through the industrial products platform, including product promotion, import and export trade, domestic and foreign logistics, supply chain finance, MRO repair and maintenance, spare parts procurement, warehouse and other services. Services. It helps enterprises carry out material management reform, strengthen resource integration, promote specialization and intensive management, and build an intelligent supply chain system. Contribute to the development of industrial Internet, industrial big data, industrial technology.',
        'mro_goods': 'MRO Commodity Line',
        'instrument_and_meter': 'Instrumentation',
        'moving_storage': 'Handling storage',
        'aerohydraulic': 'Pneumatic hydraulic',
        'tool_consumables': 'Tools & Supplies',
        'office_supplies': 'Office supplies',
        'building_material': 'Building materials',
        'power_transmission': 'Power transmission',
        'pump_valve_fittings': 'Pumps, valves and fittings',
        'auto_lighting': 'Electrical automation lighting',
        'personal_safe': 'Personal Protection Safety Protection',
        'refrigeration_HVAC': 'Refrigeration HVAC',
        'imprisoning_seal': 'Confinement seal',
        'laboratory_supplies': 'Laboratory supplies',
        'lubricating_oil_shop': 'Lubricants Workshop chemicals',
        'avrasive_metal_work_tool': 'Metalworking Abrasives',
        'mro_operation_main': 'MRO Operations and Maintenance Services',
        'mro_operation_text_1': 'Focusing on the attribute of "uncertainty supplier" of customers, MRO Operation and Maintenance Division of Zhongfa IP has set up a technical team combining the old, middle-aged and young with deep theoretical knowledge and practical experience of overhauling and repairing, mainly employees of former central enterprises, and at the same time, possesses the mature technical workers who have been engaged in operation and maintenance for many years, so that it can provide customers with professional MRO industrial services.',
        'mro_operation_text_2': 'Setting up operation and maintenance stations in wind power rich areas and industrial and mining enterprises densely populated areas, storing spare parts of perishable parts and carrying out maintenance work for customer units, so as to ensure the ability to support the operation and maintenance needs of customer units and the timeliness.',
        'mro_operation_text_3': `The operation and maintenance sites are equipped with professional repair vehicles and repair tools, while the operation and maintenance sites and operation and maintenance centers across the country maintain linkage, and reasonably and timely organize personnel and material forces to participate in emergency repair according to the customer's site conditions.`,
        'mro_warehouse_logistics': 'MRO warehouse & Logistics',
        'individual': 'size',
        'regional_center_bin': 'Regional Center Warehouse',
        'chengdu': 'Chengdu',
        'guangzhou': 'Guangzhou',
        'shenyang': 'Shenyang',
        'lanzhou': 'Lanzhou',
        'front_service_bin': 'Forward Service Warehouse',
        'hinggan_League': 'Hinggan League',
        'zhangjiakou': 'Zhangjiakou',
        'silinghot': 'Silinghot',
        'silver_city': 'Silver City',
        'xiaogan': 'Xiaogan',
        'urumchi': 'Urumqi',
        'rice_popcorn_text_1': `Popcorn platform is a cross-border e-commerce global sourcing center & distribution platform invested and operated by Guangzhou Airport Goodchains Warehouse Supply Chain Co., Ltd. The platform helps Chinese excellent brands and high-quality products develop with digital innovation ability and perfect supply chain service, and continuously empowers the cross-border e-commerce ecosystem.`,
        'rice_popcorn_text_2': `The platform seamlessly connects with eBay and Orange Union Logistics, providing partners with integrated solutions such as brand support, marketing promotion and supply chain services, opening up a brand new channel for Chinese products to go overseas.`,
        'brand_going_to_sea': 'Brands Going Overseas',
        'preferred_service': 'Preferred Services',
        'business_docking': 'Business matching',
        'build_system_database': 'Establishment of a systematic database',
        'enter_real_information': 'Real Information Matching Entry',
        'product_screening': 'Product Screening',
        'product_screening_text': `European and American Standard Audit and Certification Qualification, Product Selection and Preparation, Establishment of Quality Goods Library`,
        'market_research': 'Market research',
        'target_local': 'In response to local market trends, consumer',
        'habits_survey': 'Habits survey product consumer groups to',
        'and_market_share': 'and market share analysis of branded products',
        'position_product': 'positioning of products',
        'preferential_access': 'preferential access',
        'input_micang_brand': 'Recorded in GOODCHAINS Preferred Brand Library',
        'compliment_special': 'Complimentary Preferred Special Logo',
        'match_platform': 'Matching platform pop-up promotions',
        'enter_traffic': 'Enter the Traffic Support Preferred Program',
        'marketing_plan': 'Marketing plan',
        'diversion_day': 'Diversion throughout the day',
        'holiday_consumer_days': 'Holiday Consumer Days',
        'establishment_related': 'Establishment of related promotions',
        'precision_marketing': 'Precision marketing, pop-up promotions, etc.',
        'seller_direct': 'Seller direct',
        'one_stop_shop': 'one-stop-shop',
        'one_click_reservation': 'One-Click Reservation',
        'procurement_sales': 'Procurement sales',
        'international_logistics': 'International logistics',
        'siege_distribution': 'Siege Distribution',
        'financial_service': 'Financial service',
        'entitle_pro_lead_time': 'Entitlement to procurement lead time',
        'low_risk_and_less_worry': 'Low risk and less worry',
        'integrated_trade_services': 'Integrated trade services',
        'selected_products': 'Selected Products',
        'qualification_guarantee': 'Qualification Guarantee',
        'more_peace_of_mind': 'More peace of mind with Mee Warehouse Preferred',
        'huge_product': 'Huge product library to choose from',
        'platform_advantages': 'Platform Advantages',
        'search_ranking': 'Search Rankings',
        'inventory_visibility': 'Inventory Visibility',
        'platform_subsidies': 'Platform Subsidy',
        'traffic_support': 'Traffic Support',
        'warehouse_logistics': 'Warehouse Logistics',
        'smart_management': 'Smart Management',
        'regional_center_warehouse': 'Regional Center Warehouse',
        'overseas_service_module': 'Overseas Service Module',
        'myanmar': 'Myanmar',
        'thailand': 'Thailand',
        'malaysia': 'Malaysia',
        'ebay_partner_warehouse': 'EBAY Partner Warehouse',
        'england': 'England',
        'german': 'German',
        'america': 'America',
        'australia': 'Australia',
        'rice_outside_platform_text': `Mi Cang foreign trade integrated service platform, the introduction of "Internet +" thinking, for the owner of the cargo and international trade and logistics service providers, port management units, government supervision and other departments to provide information between the flow of documents and interactive processing, real-time sharing of operational status. It seamlessly interconnects cargo owners, service providers and financial institutions to provide one-stop comprehensive foreign trade service solutions.`,
        'platform_functions': 'Platform Functions',
        'freight_forwarder': 'Freight forwarder',
        'import_export_special': 'Import and export of special goods',
        'cross_border': 'Cross-border e-commerce integrated solutions',
        'logistic': 'Logistic',
        'international_express_parcel': 'International express parcel',
        'domestic_long_short_routes': 'Domestic long/short-haul trunk routes',
        'hongkong_transport': 'China-Hong Kong transportation, cross-border transportation',
        'port_feeder': 'Bonded Logistics, Port Feeder',
        'warehouse': 'Warehouse',
        'custom_warehouse': 'Customs Supervision Warehouse, Bonded Warehouse',
        'general_warehouse': 'General warehouse, cross-border e-commerce service warehouse',
        'overseas_warehouses': 'Overseas Warehouses, Air Ground Services',
        'customs_affairs': 'Customs affairs',
        'general_trade_market': 'General trade Processing trade Market purchases',
        'integrated_national': 'Integrated national customs clearance',
        'financial': 'Financial',
        'supply_chain_picking': 'Supply Chain Picking, Export Tax Refunds',
        'periodical_financing': 'Periodical Financing',
        'program': 'Program',
        'enterprise_single_window': 'Enterprise Single Window',
        'total_logistics_solutions': 'Total Logistics Solutions',
        'multimodal': 'Multimodal',
        'international_freight_forwarding': 'International freight forwarding',
        'maritime_transport_service': 'Maritime Transportation Services',
        'air_transport_services': 'Air transport services',
        'china_europe_train': 'China-Europe express train',
        'cross_border_log': 'Cross-border logistics',
        'china_tai_transport': 'China-Thai Transportation',
        'china_hongkong_transport': 'China-Hong Kong transportation',
        'china_vietnam': 'China-Vietnam Transportation',
        'intelligent_storage': 'Intelligent Storage',
        'overseas_warehouse': 'Overseas warehouse',
        'hk_warehouse': 'Hong Kong warehouse',
        'bonded_and_general': 'Bonded and general logistics warehouse',
        'operation_of_customs_control': 'Operation of customs control premises',
        'smart_clearance': 'Intelligent Customs Clearance',
        'O2O_text': 'O2O online declaration O2O offline synergy',
        'e_commerce_import_and_export': 'Cross-border e-commerce import and export declaration solutions',
        'rice_oversea_store_text_1': 'As a high-quality service provider of cross-border e-commerce logistics and supply chain, MICRON Group provides Chinese e-commerce enterprises and cross-border e-commerce platforms with import and export supporting services of overseas warehouse in the United Kingdom, Thailand, Malaysia and other countries as well as perfect and high-quality logistics services to solve a series of import/export logistics/trade and cross-border e-commerce logistics supply chain problems, and escort cross-border e-commerce enterprises.',
        'rice_oversea_store_text_2': 'MICRON Group has set up overseas warehouse in the United Kingdom, Thailand, Malaysia and other countries, and set up overseas localized service teams to provide customers with customs clearance and pickup, warehouse, returns and exchanges, delivery, sorting, labeling and exchange of labels, etc., which covers the headway in China and the tailgate service overseas.',
        'rice_oversea_store_text_3': 'April 14, 2022, goodchains warehouse bird UK warehouse officially selected "eBay certified docking warehouse", successfully joined the "eBay certified docking warehouse" system, more information is welcome to learn (business contact: 0755-82543109)',
        'customs_clearance': 'Customs clearance',
        'cash_on_delivery': 'Cash on Delivery',
        'product_storage': 'Product Storage',
        'returns_and_replace': 'Returns and replacements',
        'local_delivery': 'Local delivery',
        'sort_and_label': 'Sorting and labeling',
        'oversea_ware_service': 'Overseas Warehouse Services',
        'general_rade_services': 'General trade services',
        'from_china_or_world_to_mc': `Logistics services for shipment of goods from China or the world to Mi Warehouse's overseas warehouse`,
        'mc_pickup': `Mi Warehouse carries out pickup and storage in overseas warehouse countries and customs clearance at ports.`,
        'stored_in_mc': `Goods are stored in Mi Warehouse's overseas warehouse and value-added warehouse services are provided.`,
        'mi_out_store_send_your_customer': `Goods are stored in Mi Warehouse's overseas warehouse and value-added warehouse services are provided.`,
        'mc_export_for_china': 'Goodchains Warehouse purchases and delivers according to the requirements of exporting to China.',
        'overseas_warehouse_stock': 'Overseas warehouse stocking model',
        'bulk_shipment_overseas': `Bulk shipment of goods to Mi Warehouse's overseas warehouse`,
        'order_received_platforms': `Orders received on e-commerce platforms`,
        'pick_up_and_packs_goods': 'Goodchains Warehouse picks and packs goods according to order information',
        'deliver_to_your_oversea': 'We can deliver directly to your overseas customers or arrange international logistics to China.',
        'e_commerce_parcel': 'E-commerce parcel model',
        'e_commerce_returns': 'E-commerce parcel returns can be returned by the 3PL to the goodchains warehouse overseas warehouse',
        'returns_unpacked': `Returns are stored in Mi Warehouse's overseas warehouse or unpacked and shelved.`,
        're_labeled_for': 'Re-packaged and re-labeled for delivery after secondary sales',
        'please_enter_keywords': 'Enter news keywords',
        'mc_newcenter_1': 'Goodchains Warehouse Supply Chain Group Exhibits at the 2019 Yibang Future Retail Conference...',
        'mc_newcenter_2': 'MICROWARE Supply Chain Group at the 16th (Shenzhen) International Logistics Conference...',
        'mc_newcenter_3': `GOODCHAINS SCM GROUP CO.,LTD's Cross-border E-commerce Global Collection Center Officially Landed...`,
        'talent_mc_yonekura': 'Talent, the most valuable asset of GOODCHAINS SCM GROUP CO.,LTD',
        'tanlent_mc_yonekura_text_1': `The country is established by talent, and the industry is developed by talent. Talent is the root of enterprise development, is the first productive force to promote the leapfrog development of enterprises. MICRON has always been the cultivation and discovery of talent as a top priority, the company's culture is also defined around the talent. Let employees have a sense of belonging, sense of identity is our persistent goal.`,
        'tanlent_mc_yonekura_text_2': `We will never be satisfied with the status quo or stop to explore new business ideas. We always encourage our staff to pursue the spirit of excellence indefatigably. Mi Cang Group attaches importance to team building, multi-channel introduction of talents, efficient mechanism to train talents, broad platform to promote talents for enterprises in the fierce competition to stand invincible to provide a strong guarantee.`,
        'learn_more': 'Learn more',
        'job_search': 'Job Search',
        'recruitment': 'Recruitment',
        'enter_job_keyword': 'Enter job keywords',
        'workplace': 'Workplace',
        'release_time': 'Release Time',
        'recruit': 'Recruit',
        'people': 'People',
        'apply_for_position': 'Apply for a position',
        'rice_warehouse_recruitment': 'GOODCHAINS SCM GROUP CO.,LTD Recruitment Statement',
        'recruitment_text_1': 'In order to maintain a fair and orderly employment environment and effectively safeguard the legitimate rights and interests of job seekers, the company solemnly declares the following:',
        'recruitment_text_2': 'GOODCHAINS SCM GROUP CO.,LTD only publishes recruitment information in the following channels: GOODCHAINS SCM GROUP CO.,LTD Official Website',
        'recruitment_text_3': '(www.goodchains.com), BOSS Direct Recruitment (www.zhipin.com). Candidates can follow the',
        'recruitment_text_4': 'Register online and submit your resume for our recruitment campaigns by posting job openings.',
        'recruitment_text_5': `Our company's human resources department external recruitment mailbox: hr@goodchains.com; the company's recruiting staff unified use office`,
        'recruitment_text_6': `Contact job seekers by landline (0755-82543109) and e-mail (suffix@goodchains.com).`,
        'recruitment_text_7': 'The company in the recruitment process will not be any reason to charge job seekers fees, original documents, and will not be based on product sales.',
        'recruitment_text_8': 'As a condition of employment and entry on duty',
        'recruitment_text_9': 'For websites, individuals or organizations that engage in illegal activities by posing as false recruitment information released by MICRON Group, the company reserves the right to',
        'recruitment_text_10': 'Right to legal accountability',
        'recruitment_text_11': 'Job seekers should raise their awareness of fraud prevention and be guided by false information to participate in recruitment activities not organized by the Company and incur losses.',
        'recruitment_text_12': `of the company's liability for the`,
        'recruitment_text_13': 'I wish all job seekers good luck in their work and future endeavors!',
        'recruitment_text_14': 'Department of Personnel and Administration',
        'recruitment_text_15': 'June 28, 2021',
        'full': 'Full',
        'shenzhen': 'Shenzhen',
        'shanghai': 'Shanghai',
        'guangxi': 'Guangxi',
        'sichuan': 'Sichuan',
        'three_days': 'Three days',
        'one_week': 'One week',
        'in_one_month': 'In one month',
        'in_three_months': 'In three months',
        'within_half_a_year': 'Within half a year',
        'social_recruit': 'Social Recruitment',
        'campus_recruiting': 'Campus Recruiting',
        'group_recruit_statement': 'GOODCHAINS SCM GROUP CO.,LTD Recruitment Statement',
        'manpower_needs': 'Manpower needs',
        'no_title': 'No title',
        'branch_offices': 'Branch',
        'gz_airport_addr': 'No.6, Room 105, No.10, Shanxin Road, Zhonglutan Town, Baiyun District, Guangzhou, China (Airport Baiyun)',
        'gz_rice_addr': 'Room 59, Room 404, Building D, Guangzhou Airport Center, No. 1, Lugang Third Street, Huadu District, Guangzhou, China (Airport Huadu)',
        'sc_zf_addr': '21st Floor, Building 1, No.1 Aviation Road, Wuhou District, Chengdu',
        'sc_ct_addr': `YS01-1304-6, 13/F, Building 1, Chengdu International Trade Port, No.1, Juxia Road, Jinniu District, Chengdu, China`,
        'sc_nx_addr': `No. 2, 3rd Floor, 1 Standardized Factory Building, Chengdu International Railway Port Comprehensive Bonded Zone, No. 1533 Xiangdao Avenue, Qingbaijiang District, Chengdu City, Pilot Free Trade Zone, China (Sichuan)`,
        'track_no': 'Waybill number',
        'query': 'Query',
        'reservation_form': 'Reservation form',
        'inlet': 'Inlet',
        'exits': 'Exits',
        'in_store_before': 'Arrived at the forward warehouse, actual receipt data: pieces:',
        'item': 'Item',
        'weights': 'Weights',
        'volumetric': 'Volumetric',
        'gx_xyt': 'Guangxi Xingyuntong international logistics Management Co., LTD',
        'gx_xyt_addr': 'Command Office of the Construction Department of Dongxing-Vietnam Mangjie Cross-Border Economic Cooperation Zone, China',
        'gx_xyt_text_1': 'Guangxi Xingyuntong International Logistics Management Co., Ltd. is a comprehensive logistics enterprise with the supervision of freight yard operation as the core, port clearance, ground processing and other business segments in one.',
        'gx_xyt_text_2': 'As the owner unit, our company operates and manages the temporary freight yard of the second bridge of Beilun River at Dongxing Port. After the approval of the customs and relevant administrative departments, we supervise the freight yard for the customs of Dongxing Port. Customs, border defense, port road transport management and other port management departments are stationed in the joint office of the second Bridge supervision goods, and the customs clearance procedures of import and export goods can be quickly handled in the "one-stop" customs supervision yard of the Second Bridge.',
        'gz_xb': 'Qunxiang (Guangzhou) Supply Chain Management Co., Ltd.',
        'gz_rice_international': 'Guangzhou Goodchains International Freight Forwarding Co.LTD',
        'gz_xb_addr': 'Room 1507 bis, No.12 Jingang Avenue, Nansha District, Guangzhou, China',
        'gz_rice_international_addr': 'Room 58, Room 404, Block D, Guangzhou Airport Center, No. 1, Lugang Third Street, Huadu District, Guangzhou (Airport Huadu)',
        'gz_xb_text_1': `Qunxiang (Guangzhou) Supply Chain Management Co., Ltd., founded in October 2022, is a joint venture between Guangdong Airport Group Logistics Company Limited and Guangzhou MiCang International. The company aims to promote the electronic, standardization and process of the logistics industry through information technology, and establish a professional logistics supply chain service system. It provides customers with complete one-stop foreign trade integrated service solutions, including cross-border e-commerce retail yard operation, international express business, air transportation ground service, China-Hong Kong transportation, cross-border transportation, import and export customs clearance, international logistics, warehouse, multimodal transportation and other rich services to help customers solve their logistics problems.`,
        'product_case': 'Business Case',
        'contact_learn_more': 'Learn more',
        'fill_form_get_more': 'Please fill out the form below and click on the button for more information',
        'remark': 'Remark',
        'business_scope': 'Business Scope',
        'sz_micang_company': 'Shenzhen Goodchains Supply Chain CO.,Ltd',
        'sz_micang_company_addr': `602, Zhongyang Business Building, No. 24, Fuhai Avenue, Fuyong Street, Fuyong Community, Bao'an District, Shenzhen, China`,
        'sz_micang_company_bussiness': `We provide our customers with comprehensive postal customs services to ensure that goods can pass customs inspection safely and quickly. Meanwhile, we have modern warehouse facilities to provide reliable storage and handling services for our customers. In addition, we also provide flexible contract logistics services to meet the different transportation needs of our customers.`,
        'gz_micang_internation': `We provide Guangzhou Airport Pre-Security Warehouse services, aiming to provide customers with convenient cargo clearance channels and ground services. Whether it is imported, exported or transit cargo, we are able to provide our customers with efficient and reliable customs ground services. We have a professional team and advanced operating procedures to ensure that the cargoes pass the customs inspection smoothly and all necessary formalities are completed in the shortest possible time.`,
        'xg_mc': 'GOODCHAINS SCM CO., LIMITED',
        'xg_mc_addr': 'Room 1003, 10/F, Tower 1, Lippo Center, 89 Queensway, Admiralty, Hong Kong',
        'xg_mc_bussiness': ` As a company providing logistics services, we are committed to providing a full range of logistics solutions to our customers. Our services include warehouse in Hong Kong, cross-border trucking full truckload and consolidated truckload transportation, as well as delivery services within Hong Kong. With our professional team and state-of-the-art logistics facilities, we are able to provide efficient and reliable logistics services to our customers. Whether for individual users or corporate clients, we are able to provide tailor-made logistics solutions to meet the different needs of our customers.`,
        'sc_micang_nx': 'Sichuan Micang Nanxiang International Trade Co., Ltd.',
        'sc_micang_nx_addr': 'No.1, 2F, Building 14, No.500, Hangshu Avenue, Xihanggang Street, Shuangliu District, Chengdu City, Pilot Free Trade Zone, China (Sichuan)',
        'sc_micang_nx_bussiness': `We provide our customers with comprehensive postal customs services to ensure that goods can pass customs inspection safely and quickly. Meanwhile, we have modern warehouse facilities to provide reliable storage and handling services for our customers. In addition, we also provide flexible contract logistics services to meet the different transportation needs of our customers.`,
        'sc_micang_goodchains': 'Sichuan Goodchains Warehouse Supply Chain Management Co., Ltd. ',
        'sc_micang_goodchains_addr': '2-02-1, Comprehensive Office Building of Airport Bonded Logistics Center, No.500 Hangshu Avenue, Xihanggang Street, Shuangliu District, Chengdu City, Pilot Free Trade Zone of China (Sichuan)',
        'sc_micang_goodchains_bussiness': `We provide Chengdu air/public transportation services, aiming to provide customers with efficient cargo transportation solutions. As a partner of Chengdu Shuangliu Port, we undertake the transportation of goods from the port to the destination. At the same time, we also provide postal declaration services to ensure that the cargo can go through customs clearance procedures smoothly. In addition, we also provide ground services, including warehouse, distribution and other value-added services, to meet the different logistics needs of our customers.`,
        'fs_mc':'Foshan Goodchains Warehouse Supply Chain Management Co., Ltd. ',
        'fs_mc_addr':'204a, Port Building, Wufeng 3rd Road, Chancheng District, Foshan, China',
        'fs_mc_busssiness':`We provide Foshan Chancheng Yard cross-border customs clearance services in order to provide smooth customs clearance for import and export goods. Our services are applicable to cases such as transshipment to Guangzhou Airport for departure, transshipment to Nanhai Shipping for departure, transshipment to Southeast Asia for transit trucks, China-Hong Kong and China-Macau trucks for domestic 9610 and 9710 declarations, and so on. With our professional team and advanced operation process, we can provide customers with efficient and reliable customs clearance service.`,
        'main_projects':'Project Case',
        'postal_declaration':'Postal Declaration Project',
        'air_road_service':'Air-to-public, globally compliant transportation services',
        'cn_lao_liner':`China-Laos-Thai liner service`,
        'gz_airport_forward':`Pre-screening of forward storage at Guangzhou Airport`,
        'dx_port_terminal':'Dongxing Port Terminal Construction',
        'fs_port_treminal':'Foshan Port Terminal Operation',
        'fs_mc_text_1':`Foshan Goodchains around the Foshan port (cross-border e-commerce customs clearance center) to provide customers with Foshan China-Hong Kong car transport customs clearance, Southeast Asia land transport transit cross-border 9610/9710 services, is the Mi Cang's own operation of the checking station, a collection of customs sealing, checking, loading and unloading, customs declaration and other services, according to the nature of the goods and the needs of the customer to provide the relevant customs clearance and transportation solutions.`,
        'gz_inter_text_1':'Guangzhou Goodchains International Freight Forwarding Co.LTD. is a company that specializes in providing customers with air ground services and customs clearance services for import and export cargo. We are centered around Guangzhou Baiyun Airport, providing services including pickup, collection, palletizing, weighing, cargo handling, labeling, warehouse delivery, etc. We also mainly deal with export air and land 9610/9710 from Guangzhou Airport, as well as air 0110 customs clearance. We provide customs clearance and transportation solutions according to the nature of goods and customer needs.',
        'gz_inter_text_2':'Has a front warehouse located in Jingdong Asia One Logistics Park, covering an area of 3,000 square meters, only 3 kilometers from Baiyun Airport; and has a customs clearance team, customer service team, on-site operators, team members are professional and cohesive.',
        'gz_inter_text_3':'Based on Guangzhou airport port, we link up with China-Hong Kong truck transportation, multi-port business, and deep cooperation with airport cargo station, with strong customs capacity and on-site processing capability.',
        'gz_inter_text_4':'We have won high recognition from our customers by proactively communicating and solving their difficulties. We always take customer experience as the core of our service, and are committed to providing customers with more efficient, hassle-free and better one-stop services.',
        'dx_text_1': 'Located in China Dongxing - Vietnam Mangjie cross-border economic cooperation zone in the Chinese region, adjacent to the second bridge of Beilun River between China and Vietnam, the total land area of about 249 acres of planning, with a total floor area of more than 24,000 square meters and a total investment of about 220 million yuan, the main construction of the integrated declaration area, inspection and quarantine processing area, on-site inspection area, Customs Supervision and Inspection Depot Area, H986 inspection area, customs clearance channel and other six functional areas. The designed normal customs clearance cargo volume of 8 million tons per year, the daily customs clearance capacity of 2,000 vehicle trips.',
        'dx_text_2': `The main construction content of Dongxing Port Second Bridge Cargo Inspection Yard (Customs Supervision and Operation Site) includes comprehensive declaration area, inspection and quarantine processing area, on-site inspection area, customs supervision warehouse area, H986 inspection area, customs clearance channel and other buildings, on-site roads, outdoor green space and other ancillary works, as well as electricity, sewage, communications and other municipal facilities. The original old temporary cargo yard is only more than 20 acres, now the new yard is 249 acres, expanding about 10 times; the original our old cargo yard is one in one out of the choke point, now it is 6 in 6 out, the speed of customs clearance has increased dramatically, and effectively promote the healthy and rapid development of Dongxing local economy.`,
        'submit_suc':'Submitted successfully',
        'submit_err':'Submission failed, please try again',
        'main_programs':'Main Programs',
        'news_title_dynamic':'Actively fight the epidemic and overcome the difficulties together'
    }
}