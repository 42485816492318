export default {
    'home': {
        'home_page': '首页',
        'about_rice_barn': '关于米仓',
        'business_and_service': '业务与服务',
        'dynamic': '米仓动态',
        'join_us': '加入我们',
        'contact_us': '联系我们',
        'enterprise_overview': '企业概括',
        'branch_architecture': '分支架构',
        'main_business': '主营业务',
        'rice_industrial_platform': '米仓工业品平台',
        'rice_popcorn_platform': '米仓爆米花平台',
        'rice_outside_platform': '米仓外综服平台',
        'rice_oversea_store': '米仓海外仓储',
        'rice_introduction': '米仓简介',
        'culture_and_vision': '文化与愿景',
        'social_responsibility': '社会责任',
        'gz_airport_rice': '广州空港米仓供应链有限公司',
        'sc_ct': '四川成泰南向国际贸易有限公司',
        'sc_zf': '四川中发工品科技发展有限公司',
        'gz_rice': '广州米仓供应链管理有限公司',
        'sc_rice': '四川米仓南向供应链管理有限公司',
        'legal_statement': '法律声明',
        'legal_statement_1': '米仓集团网站(以下简称“本网站”)由米仓集团创设。任何人进入、浏览和使用本网站即承认已阅读、理解并同意受本声明约束，并遵守所有适用的法律和法规。',
        'copyright_notice': '版权声明',
        'copyright_notice_1': '本网站(https://www.goodchains.cn/)上全部内容为版权人所拥有，在此并未作任何授权。本网站上的内容仅限于为信息，供个人或非商业之目的使用，并且不得在任何网络计算机上复制或公布，也不得在任何媒体上传播。未经版权人许可，任何人不得擅自(包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载)使用。否则版权人将依法追究其法律责任。',
        'disclaimer': '免责声明',
        'disclaimer_1': '版权人拥有对本网站的内容进行随时更改之权利，之前将不会另行通知。版权人不保证或声明本网站展示的资料数据库是否正确、全面或是最新数据。本网站内的所有图片(包括但不限于插图、透视图、设计图等)、模型、文字描述等，仅作为参考信息，不作为任何承诺或要约。任何人因本网站或依赖其内容进行交易所引致的损失，版权人均不承担任何责任。',
        'website_map': '网站地图',
        'draw_about': '关于米仓',
        'enterprise_profile': '企业概况',
        'branch': '分支机构',
        'draw_business_service': '业务与服务',
        'social_recruitment': '社会招聘',
        'micang_recruitment_statement': '米仓集团招聘声明',
        'address': '地址',
        'mail': '邮箱',
        'phone': '联系电话',
        'back': '返回',
        'waybill_query': '运单查询',
        'micang_group': '米仓供应链集团有限公司',
        'company_addr': '深圳市宝安区福海大道24号中阳商务大厦602',
        'postcode': '邮编',
        'telephone': '电话',
        'company_info': '是一家以自有口岸为核心，集跨境电商、口岸通关、地面处理、货运代理和供应链管理等几大业务板块于一体的综合性物流企业。总部位于深圳，同时在上海，广州，成都，香港，泰国，马来西亚等地设立多间分公司...',
        'detailed_content': '详细内容',
        'popcorn': '爆米花',
        'garment': '外综服',
        'industrial_products': '工业品',
        'overseas_storage': '海外仓储',
        'micang_group_text_1': '米仓供应链集团是一家以跨境物流与贸易为核心，集跨境电商、口岸通关、地面处理、货运代理和供应链管理等几大业务板块于一体的综合性物流服务企业。集团总部位于深圳，同时在上海，广州，佛山，成都，香港，泰国，马来西亚，缅甸，德国等地设立多家分公司。',
        'micang_group_text_2': '在国内，米仓供应链依托华南及西南地区的自有口岸资源，通过市场分析及资源整合，建立“多口岸、多渠道、全方位”的服务体系，并开展兼顾服务个性化及执行标准化的服务模式，在提供货运代理、口岸通关、地面处理、仓储管理等标准化基础服务的同时，不断贴近客户需求,深化服务内容，结合创新理念及信息技术，为客户设计个性化的物流解决方案，为客户的供应链管理提供专业化、高效率的物流服务，有效降低客户的供应链风险，助力客户在激烈的市场竞争中脱颖而出。',
        'micang_group_text_3': '攻坚克难，砥砺前行，米仓全体员工秉承着十足的热情，始终铭记成立伊始的初心与前行路上的使命，以质量谋求发展，以业绩赢得肯定，通过不断的探索，一步步将米仓集团打造为中国领先的供应链服务企业。',
        'corporate_culture': '企业文化',
        'intellectual_management': '智心经营',
        'meticulous_service': '精心服务',
        'grow_together': '齐心成长',
        'gorporate_vision': '企业愿景',
        'margin_mc_world_win': '缘聚米仓，与世界共赢',
        'over_difficult_together': '2022年，香港疫情爆发期间，中港车队协助中建科工运输过港物资新闻',
        'help_each_other': '抗击疫情 你我同行 坚守防线 守望相助',
        'view_count': '浏览次数',
        'help_each_other_text': '成都市商务局紧急委托米仓集团采购疫情防控应急物资，供给疫情重灾区的一些医疗卫生机构，共同抗击疫情。米仓集团充分发挥自身优势，依托自身供应链资源，快速完成资源整合与供应。',
        'help_each_other_text_2': '2020年1月29日下午，来自泰国的第一批物资从泰国曼谷飞抵成都双流机场，十万只口罩通过成都市政府提供给急需的医疗机构，这批物资也是西南地区第一批海外采购物资。疫情期间，米仓集团为各地政府和医疗机构采购供给数百万医疗物资，高质量、高效率的资源整合与物流能力得到了社会各界的肯定。',
        'gz_airport_text_1': '广州空港米仓供应链有限公司，成立于2021年，由广州空港产业投资集团有限公司与米仓供应链集团有限公司合资成立,注册资金为人民币3,000万元，空港集团占比51%，米仓集团占比49%。公司旨在充分利用广州临空经济示范区和广州白云机场综保区优势，以跨境业务为牵引，携手央国企、上市公司及行业龙头，集国际物流、进出口通关、仓储、跨境运输等外贸综合服务，提供一站式采销供应链服务，涵盖原材料、农产品、林木业、工业品、航材、新材料、电子产品和食品等领域，积极构建自主可控、安全可靠的供应链体系。',
        'mall_entrance': '商城入口',
        'sc_ct_text_1': '由四川米仓国际贸易有限公司与大型国有独资企业：成都市金牛国投置业有限公司、成都金牛环城投资管理有限公司合资成立，属国有控股企业。公司主营业务包括外贸综合服务、进出口清关服务、海外仓服务、国际物流服务等，并在泰国设立分公司，与东盟各国政府企业往来密切，进一步开拓各类进出口商品贸易、服务贸易等业务。同时公司在泰国曼谷设立海外仓库。',
        'sc_ct_text_2': '致力将成都商品带到东南亚，扩大成都与东盟国家间的贸易、物流等方面的深度合作',
        'sc_zf_text_1': '米仓集团旗下四川中发工品科技发展有限公司致力于打造专业的MRO工业品一站式采购服务平台，在提倡“阳光采购、降本增效、大平台、大监管”的理念下，通过平台向国内外大中型工业企业提供产品和服务，包括构建智能化供应链体系、 产品推广、进出口贸易、境内外物流、供应链金融、MRO维修维护、备品备件 采购、仓储等服务。为工业互联网、工业大数据、工业科技发展贡献力量。',
        'gz_rice_text_1': '广州米仓于2020年3月正式挂牌营业，初期主要以代理出口防疫物资和仓储业务为主。5月中旬开始，与爱派克斯国际货运代理有限公司于5月中旬达成战略合作伙伴关系，代理其包机业务和9610、0110出口业务。',
        'gz_rice_text_2': '公司开展业务以来，逐渐先后与物流行业内著名企业建立稳固持久的合作关系，业务总体保持快速增长。进入2021年以来，在保持原有业务继续平稳发展的前提下，努力寻求更多合作伙伴，业务范围继续向国内西南、西北地区不断延伸，并尝试开拓国内其他地区的市场空间。',
        'sc_rice_text_1': '四川米仓南向供应链管理有限公司依托米仓集团强大的供应链组织能力、外贸综合服务能力、自有口岸资源以及东南亚物流及贸易资源等，有效整合西南及华南的特色产业链，全面开展中老铁路外综服体系搭建、供应链全链条服务、国际贸易金融体系等建设，全面打造RCEP贸易枢纽平台。为青白江及成都建设西部地区最具影响力的国际贸易枢纽助力，开启中老铁路运输新纪元。',
        'sc_rice_text_2': '四川米仓南向供应链管理有限公司为客户提供全国各地-青白江-东南亚（老挝、泰国、缅甸、越南、马来西亚）的进出口货物物流运输服务（含普货和冷链运输），为客户提供全境物流解决方案及实施服务，包含铁路货运代理服务及境内外通关、集装箱租赁、库存管理、冷链服务等。',
        'mc_indu_mall': '米仓工业品商城',
        'mc_indu_mall_1': '四川中发工品科技发展有限公司是米仓集团旗下子公司，致力于打造专业的MRO工业品一站式采购服务平台，是一家以互联网科技赋能企业发展、创新能力突出的国有大型高科技企业。',
        'mc_indu_mall_2': '米仓MRO工业品平台提倡“阳光采购、降本增效、大平台、大监管”的服务理念，通过工业品平台向国内外大中型工业企业提供产品和服务，包括产品推广、进出口贸易、境内外物流、供应链金融、MRO维修维护、备品备件采购、仓储等服务。助力企业进行物资管理改革、加强资源整合，促进专业化、集约化管理、构建智能化供应链体系。为工业互联网、工业大数据、工业科技发展贡献力量。',
        'mro_goods': 'MRO商品线',
        'instrument_and_meter': '仪器仪表',
        'moving_storage': '搬运存储',
        'aerohydraulic': '气动液压',
        'tool_consumables': '工具耗材',
        'office_supplies': '办公用品',
        'building_material': '建筑材料',
        'power_transmission': '动力传输',
        'pump_valve_fittings': '泵阀管件',
        'auto_lighting': '电器自动化照明',
        'personal_safe': '个人防护 安全防护',
        'refrigeration_HVAC': '制冷暖通',
        'imprisoning_seal': '禁锢密封',
        'laboratory_supplies': '实验室用品',
        'lubricating_oil_shop': '润滑油 车间化学品',
        'avrasive_metal_work_tool': '金属加工 磨具磨料',
        'mro_operation_main': 'MRO运维服务',
        'mro_operation_text_1': `中发工品MRO运维事业部围绕客户“不 确定性的供给者”的属性，组建了一支 以原央企单位员工为主体的老中青相结 合的具有深厚理论知识及检修实战经验 的技术队伍，同时拥有多年从事运维工 作的成熟技术工人力量，为客户提供专 业MRO工业服务。`,
        'mro_operation_text_2': '在风电富集区域及工矿企业密集区布置 运维站点，储存客户单位易损部件备件 及进行维修工作，确保对用户单位的运 维需求支持能力及时效性。',
        'mro_operation_text_3': '运维站点配备专业的抢修作业车及抢修 工具，同时全国各运维站点及运维中心 保持联动，根据客户现场情况，合理及 时组织人员及物资力量参与应急抢修。',
        'mro_warehouse_logistics': 'MRO仓储物流',
        'individual': '个',
        'regional_center_bin': '区域中心仓',
        'chengdu': '成都',
        'guangzhou': '广州',
        'shenyang': '沈阳',
        'lanzhou': '兰州',
        'front_service_bin': '前置服务仓',
        'hinggan_League': '兴安盟',
        'zhangjiakou': '张家口',
        'silinghot': '锡林浩特',
        'silver_city': '银城',
        'xiaogan': '孝感',
        'urumchi': '乌鲁木齐',
        'rice_popcorn_text_1': '“爆米花平台是米仓集团旗下的广州空港米仓供应链有限公司投资运营的跨境电商全球集采中心&分销平台。平台以数字化创新能力、供应链完善服务助力中国优秀品牌及优质产品发展，持续赋能跨境电商生态体系。',
        'rice_popcorn_text_2': `平台无缝对接eBay、橙联物流，为合作伙伴提供品牌扶持、营销推广、供应链服务等一体化解决方案，为中国产品出海开辟出崭新的航道。`,
        'brand_going_to_sea': '品牌出海',
        'preferred_service': '优选服务',
        'business_docking': '商务对接',
        'build_system_database': '建立系统数据库',
        'enter_real_information': '真实信息对接录入',
        'product_screening': '产品筛查',
        'product_screening_text': ' 欧美标准审核认证认证 资格，选品备品，建立 优质商品库',
        'market_research': '市场调研',
        'target_local': '针对当地市场趋势，消费',
        'habits_survey': '习惯调查产品消费群体以',
        'and_market_share': '及市场占有率分析品牌产',
        'position_product': '品定位',
        'preferential_access': '优选入库',
        'input_micang_brand': '录入米仓优选品牌库',
        'compliment_special': '赠送优选特别标识',
        'match_platform': '匹配平台爆款促销活动',
        'enter_traffic': '进入流量扶持优选计划',
        'marketing_plan': '营销策划',
        'diversion_day': '全天时引流',
        'holiday_consumer_days': '节假日消费日活动',
        'establishment_related': '建立相关促销活动',
        'precision_marketing': '精准营销、爆款促销等',
        'seller_direct': '卖家直采',
        'one_stop_shop': '一站无忧',
        'one_click_reservation': '一键预约',
        'procurement_sales': '采购销售',
        'international_logistics': '国际物流',
        'siege_distribution': '围城配送',
        'financial_service': '金融服务',
        'entitle_pro_lead_time': '享有采购账期',
        'low_risk_and_less_worry': '低风险少担忧',
        'integrated_trade_services': '综合贸易服务',
        'selected_products': '优选商品',
        'qualification_guarantee': '资质认证保障',
        'more_peace_of_mind': '米仓优选更安心',
        'huge_product': '海量产品库供选',
        'platform_advantages': '平台优势',
        'search_ranking': '搜索排名',
        'inventory_visibility': '库存可见',
        'platform_subsidies': '平台补贴',
        'traffic_support': '流量扶持',
        'warehouse_logistics': '仓储物流',
        'smart_management': '智能管理',
        'regional_center_warehouse': '区域中心仓',
        'overseas_service_module': '海外服务舱',
        'myanmar': '缅甸',
        'thailand': '泰国',
        'malaysia': '马来西亚',
        'ebay_partner_warehouse': 'EBAY合作仓',
        'england': '英国',
        'german': '德国',
        'america': '美国',
        'australia': '澳大利亚',
        'rice_outside_platform_text': '米仓外贸综合服务平台，引入“互联网+”思维，为货主与国际贸易及物流服务商、口岸经营单位政府监管等部门之间提供信息单证的流转与交互处理、作业状态实时共享。将货主、服务商、金融机构等无缝互联，提供一站式外贸综合服务解决方案。',
        'platform_functions': '平台功能',
        'freight_forwarder': '货代',
        'import_export_special': '特种货物进出口',
        'cross_border': '跨境电商综合解决方案',
        'logistic': '物流',
        'international_express_parcel': '国际快件小包',
        'domestic_long_short_routes': '国内长/短途干线',
        'hongkong_transport': '中港运输、跨境运输',
        'port_feeder': '保税物流、港口接驳车',
        'warehouse': '仓储',
        'custom_warehouse': '海关监管仓、境保税仓',
        'general_warehouse': '普通仓 、跨境电商服务仓',
        'overseas_warehouses': '海外仓 、航空地面服务',
        'customs_affairs': '关务',
        'general_trade_market': '一般贸易 加工贸易 市场采购',
        'integrated_national': '全国一体化通关',
        'financial': '金融',
        'supply_chain_picking': '供应链代采、出口退税',
        'periodical_financing': '账期融资',
        'program': '方案',
        'enterprise_single_window': '企业级单一窗口',
        'total_logistics_solutions': '物流整体解决方案',
        'multimodal': '多式联运',
        'international_freight_forwarding': '国际货代',
        'maritime_transport_service': '海运服务',
        'air_transport_services': '空运服务',
        'china_europe_train': '中欧班列',
        'cross_border_log': '跨境物流',
        'china_tai_transport': '中泰运输',
        'china_hongkong_transport': '中港运输',
        'china_vietnam': '中越运输',
        'intelligent_storage': '智能仓储',
        'overseas_warehouse': '海外仓',
        'hk_warehouse': '香港仓',
        'bonded_and_general': '保税及普通物流仓',
        'operation_of_customs_control': '海关监管场所运营',
        'smart_clearance': '智慧通关',
        'O2O_text': 'O2O线上申报 O2O线下协同',
        'e_commerce_import_and_export': '跨境电商进出口申报解决方案',
        'rice_oversea_store_text_1': '米仓集团作为跨境电商物流供应链优质服务商，为中国电商企业、跨境电商平台提供英国、泰国、马来西亚等国家的海外仓进出口配套服务及完善优质的物流服务，解决一系列进出口物流/贸易、跨境电商物流供应链问题，为跨境电商企业保驾护航。',
        'rice_oversea_store_text_2': '米仓集团在英国、泰国、马来西亚等国家布局海外仓，搭建海外本地化服务团队，为客户提供清关提货、仓储、退换货、派送、分拣、贴换标等服务，涵盖中国境内头程及海外尾程服务。',
        'rice_oversea_store_text_3': '2022年4月14日，米仓飞鸟英国仓正式入选“eBay认证对接仓”，成功加入“eBay认证对接仓”体系，更多信息欢迎了解（业务联系：0755-82543109）',
        'customs_clearance': '清关提货',
        'cash_on_delivery': '货到付款',
        'product_storage': '产品仓储',
        'returns_and_replace': '退换货',
        'local_delivery': '本土派送',
        'sort_and_label': '分拣、贴换标',
        'oversea_ware_service': '海外仓服务',
        'general_rade_services': '一般贸易服务',
        'from_china_or_world_to_mc': '货物从中国或全球发往米仓海外仓物流服务',
        'mc_pickup': '米仓进行海外仓国家境内提货入仓、口岸清关提货',
        'stored_in_mc': '货物存储在米仓海外仓并提供仓储增值服务',
        'mi_out_store_send_your_customer': '米仓海外仓直接将货物派送至您在国外的客户',
        'mc_export_for_china': '米仓按出口中国要求进行采购及发货',
        'overseas_warehouse_stock': '海外仓备货模式',
        'bulk_shipment_overseas': '货物批量发往米仓海外仓',
        'order_received_platforms': '电商平台上收到订单',
        'pick_up_and_packs_goods': '米仓根据订单信息分拣打包商品',
        'deliver_to_your_oversea': '米仓直接派送给您在海外的客户或安排国际物流直邮发往中国',
        'e_commerce_parcel': '电商小包模式',
        'e_commerce_returns': '电商小包退件可由3PL将退件退回米仓海外仓',
        'returns_unpacked': '退件存储在米仓海外仓或拆包上架',
        're_labeled_for': '二次销售后重新打包贴单派送',
        'please_enter_keywords': '输入新闻关键字',
        'mc_newcenter_1': '米仓供应链集团参展2019亿邦未来零售大会...',
        'mc_newcenter_2': '米仓供应链集团亮相第十六届（深圳）国际物流...',
        'mc_newcenter_3': '米仓供应链集团跨境电商全球集采中心正式落地...',
        'talent_mc_yonekura': '人才，米仓最宝贵的资产',
        'tanlent_mc_yonekura_text_1': '国以才立，业以才兴。人才是企业发展的根本，是推动企业跨越式发展的第一生产力。米仓一直将人才的培养与发掘作为重中之重，公司文化亦围绕人才所定义。让员工有归属感，认同感是我们持之以恒的目标。',
        'tanlent_mc_yonekura_text_2': '我们绝不满足现状、停止脚步，探索新的经营思路。我们一直鼓励员工不屈不挠的追求卓越的精神。米仓集团重视团队建设，多渠道引进人才、高效机制培养人才、广阔平台晋升人才为企业在激烈竞争中立于不败之地提供强有力的保障。',
        'learn_more': '了解更多',
        'job_search': '职位搜索',
        'recruitment': '人员招聘',
        'enter_job_keyword': '输入职位关键词',
        'workplace': '工作地点',
        'release_time': '发布时间',
        'recruit': '招聘',
        'people': '人',
        'apply_for_position': '申请职位',
        'rice_warehouse_recruitment': '米仓供应链集团有限公司招聘声明',
        'recruitment_text_1': '为维持公平、有序的就业环境，切实维护求职者合法权益，公司郑重声明如下：',
        'recruitment_text_2': '米仓供应链集团有限公司仅在以下渠道发布招聘信息： 米仓供应链集团有限公司官网',
        'recruitment_text_3': '(www.goodchains.com) 、 BOSS直聘（www.zhipin.com）上发布。广大应聘者可根据',
        'recruitment_text_4': '发布的职位信息，在网上注册并投递简历，参加本公司的招聘活动;',
        'recruitment_text_5': '我公司人力资源部对外招聘邮箱为 ：hr@goodchains.com;公司招聘人员统一使用办',
        'recruitment_text_6': '公座机电话 (0755-82543109) 和邮箱 (后缀@goodchains.com)联系求职者;',
        'recruitment_text_7': '公司在招聘过程中不会以任何理由收取求职者费用 、证件原件 ，不会以产品推销为由',
        'recruitment_text_8': '作为录用和入职条件',
        'recruitment_text_9': '对于冒充米仓集团发布的虚假招聘信息，从事非法活动的网站、个人或组织，公司保留',
        'recruitment_text_10': '追究法律责任的权利',
        'recruitment_text_11': '求职者应提高防诈骗意识，受虚假信息引导，参加非本公司组织的招聘活动而造成损失',
        'recruitment_text_12': '的，自行承担相应的责任',
        'recruitment_text_13': '祝广大求职者工作顺利、前程似锦!',
        'recruitment_text_14': '人事行政部',
        'recruitment_text_15': '2021年6月28日',
        'full': '全部',
        'shenzhen': '深圳',
        'shanghai': '上海',
        'guangxi': '广西',
        'sichuan': '四川',
        'three_days': '三天内',
        'one_week': '一周内',
        'in_one_month': '一个月内',
        'in_three_months': '三个月内',
        'within_half_a_year': '半年内',
        'social_recruit': '社会招聘',
        'campus_recruiting': '校园招聘',
        'group_recruit_statement': '米仓集团招聘声明',
        'manpower_needs': '人才需求',
        'no_title': '没有标题',
        'branch_offices': '分公司',
        'gz_airport_addr': '广州市白云区钟落潭镇善信路10号105室自编6号(空港白云)',
        'gz_rice_addr': '广州市花都区绿港三街1号广州空港中心D栋404房自编59室(空港花都)',
        'sc_zf_addr': '成都市武侯区航空路1号1栋21层',
        'sc_ct_addr': '成都市金牛区聚霞路1号成都国际贸易港1号楼13楼YS01-1304-6',
        'sc_nx_addr': '中国(四川)自由贸易试验区成都市青白江区香岛大道1533号成都国际铁路港综合保税区1栋标准化厂房3层2号分区内',
        'track_no': '运单号',
        'query': '查询',
        'reservation_form': '预约单',
        'inlet': '进口',
        'exits': '出口',
        'in_store_before': '已到前置仓，实际收货数据：件数:',
        'item': '件',
        'weights': '重量',
        'volumetric': '体积',
        'gx_xyt': '广西兴云通国际物流管理有限公司',
        'gx_xyt_addr': '中国东兴--越南芒街跨境经济合作区建设部指挥办公室',
        'gx_xyt_text_1': '广西兴云通国际物流管理有限公司是一家以监管货场运营为核心、口岸通关、地面处理等业务板块于一体的综合性物流企业。',
        'gx_xyt_text_2': '我司作为业主单位经营管理东兴口岸北仑河二桥临时货场，经海关及相关行政主管部门批复，为东兴口岸海关监管货场。海关、边防、口岸道路运输管理等口岸管理部门进驻二桥监管货物联合办公，进出口货物一应通关手续可在二桥海关监管货场“一站式”快捷办理。',
        'gz_xb': '广州小白供应链管理有限责任公司',
        'gz_rice_international': '广州米仓国际货运代理有限公司',
        'gz_xb_addr': '广州市南沙区进港大道12号1507房之二                     ',
        'gz_rice_international_addr': '广州市花都区绿港三街1号广州空港中心D栋404房自编58室（空港花都）',
        'gz_xb_text_1': '广州小白供应链有限责任公司，成立于2022年10月，由广东省机场集团物流有限公司与广州米仓国际合资组建。公司旨在通过信息化手段推进物流行业电子化、标准化和流程化，建立专业的物流供应链服务体系。为客户提供完备的一站式外贸综合服务解决方案，包括跨境电商零售场站经营、国际快件业务、航空运输地面服务、中港运输、跨境运输、进出口通关、国际物流、仓储、多式联运等丰富的服务，帮助客户解决物流难题。',
        'product_case': '产品案例',
        'contact_learn_more': '了解更多详情',
        'fill_form_get_more': '请填写下表并点击按钮以获取更多信息',
        'remark': '备注',
        'business_scope': '业务范围',
        'sz_micang_company': '深圳市米仓供应链有限公司',
        'sz_micang_company_addr': '深圳市宝安区福永街道福永社区福海大道24号中阳商务大厦602',
        'sz_micang_company_bussiness': '我们为客户提供完善的邮政关务服务，以确保货物能够安全快捷地通过海关检查。同时，我们拥有现代化的仓储设施，可以为客户提供可靠的存储和处理服务。除此之外，我们还提供灵活的合约物流服务，以满足客户不同的运输需求。',
        'gz_micang_internation': '我们提供广州机场预安检仓服务，旨在为客户提供便捷的货物清关通道和地面服务。无论是进出口货物还是过境货物，我们都能够为客户提供高效、可靠的关务地面服务。我们拥有专业的团队和先进的操作流程，以确保货物顺利通过海关检查，并在最短的时间内完成所有必要的手续。',
        'xg_mc': '香港米仓供应链管理有限公司',
        'xg_mc_addr': '金鐘金鐘道89號 力寶中心第一座10樓1003室, 香港',
        'xg_mc_bussiness': '作为一家提供物流服务的公司，我们致力于为客户提供全方位的物流解决方案。我们的服务包括香港仓储、跨境卡车整车和拼车运输，以及香港境内的派送服务。我们拥有专业的团队和先进的物流设施，可以为客户提供高效、可靠的物流服务。无论是对于个人用户还是企业客户，我们都能够提供量身定制的物流解决方案，以满足客户不同的需求。',
        'sc_micang_nx': '四川米仓南向国际贸易有限公司',
        'sc_micang_nx_addr': '中国(四川)自由贸易试验区成都市双流区西航港街道航枢大道500号14栋2层1号',
        'sc_micang_nx_bussiness': '我们为客户提供完善的邮政关务服务，以确保货物能够安全快捷地通过海关检查。同时，我们拥有现代化的仓储设施，可以为客户提供可靠的存储和处理服务。除此之外，我们还提供灵活的合约物流服务，以满足客户不同的运输需求。',
        'sc_micang_goodchains': '四川米仓供应链管理有限公司',
        'sc_micang_goodchains_addr': '中国(四川)自由贸易试验区成都市双流区西航港街道航枢大道500号空港保税物流中心综合办公楼2-02-1',
        'sc_micang_goodchains_bussiness': '我们提供成都空公联运服务，旨在为客户提供高效的货物运输解决方案。作为成都双流口岸的合作伙伴，我们承接货物从口岸出发至目的地的全程运输服务。同时，我们还提供邮政申报服务，确保货物能够顺利进行报关手续。此外，我们还提供地面服务，包括仓储、配送和其他增值服务，以满足客户不同的物流需求。',
        'fs_mc': '佛山米仓供应链管理有限公司',
        'fs_mc_addr': '佛山市禅城区五峰三路口岸大楼204a',
        'fs_mc_busssiness': `我们提供佛山禅城场站跨境清关服务，以便为客户提供顺畅的进出口货物通关服务。我们的服务适用于转广州机场离境、转南海海运离境、转东南亚过境卡车、中港及中澳卡车进行国内9610及9710申报等情况。我们拥有专业的团队和先进的操作流程，可以为客户提供高效、可靠的清关服务。`,
        'main_projects': '项目案例',
        'postal_declaration': '邮政申报项目',
        'air_road_service': '空公联运、全球合规运输服务',
        'cn_lao_liner': '中老泰班列服务',
        'gz_airport_forward': '广州机场前置仓预安检',
        'dx_port_terminal': '东兴口岸场站建设',
        'fs_port_treminal': '佛山口岸场站运营',
        'fs_mc_text_1': '佛山米仓围绕佛山口岸（跨境电商清关中心）为客户提供佛山中港车运输报关、东南亚陆运转关跨境9610/9710服务，是米仓的自有运营的查验场站，集合了封关、查验、装卸、报关等服务，根据货物性质和客户需求提供相关的清关和运输解决方案。',
        'gz_inter_text_1': '广州米仓国际货运代理有限公司是一家专注于为客户提供进出口货物的航空地面服务和报关服务的企业。我们围绕广州白云机场，提供包括提货、收货、打托、过磅、理货、贴标、交仓等服务，并主要处理广州机场出口空运和陆运9610/9710，以及空运0110报关。我们根据货物性质和客户需求，提供清关和运输解决方案。',
        'gz_inter_text_2': '拥有位于京东亚洲一号物流园的前置仓，占地3000平方米，距离白云机场仅3公里；并拥有报关团队、客服团队、现场操作人员，团队成员专业且凝聚力强。',
        'gz_inter_text_3': '基于广州机场口岸，我们联动中港车运输、多口岸业务，并与机场货站深度合作，拥有强大的关务能力和现场处理能力。',
        'gz_inter_text_4': '我们积极主动沟通，解决客户困难，赢得了客户的高度认可。我们始终将客户体验作为服务核心，致力于为客户提供更高效、更省心、更优质的一站式服务。',
        'dx_text_1': ' 位于中国东兴——越南芒街跨境经济合作区中方区域，毗邻中越北仑河二桥，规划总用地面积约249亩，总建筑面积为2万4千多平方米，总投资约2.2亿多元，主要建设综合申报区、检验检疫处理区、现场查验区、海关监管库区、H986查验区、通关通道等六大功能区。设计常态通关货运量为每年800万吨，日通关能力为2000辆车次。',
        'dx_text_2': '东兴口岸二桥验货场（海关监管作业场所）主要建设内容包括综合申报区、检验检疫处理区、现场查验区、海关监管库区、H986查验区、通关通道及其他用房、场内道路、室外绿地等附属工程、以及电力、污水、通讯等市政配套设施等。原来旧的临时货场只有20多亩，现在新的货场是249亩，扩大了大约10倍；原来我们的旧货场是一进一出的卡口，现在是6进6出，通关速度大幅提升，有效促进东兴地方经济健康快速发展。',
        'submit_suc': '提交成功',
        'submit_err': '提交失败，请重试',
        'main_programs':'主营项目',
        'news_title_dynamic':'积极抗疫，共克时艰'
    },
}