import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/index",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/Home/index.vue"),
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/Index/index.vue"),
        meta: {
          title: "首页",
          keepAlive: true
        }
      },
      {
        path: "/innerIndex",
        name: "innerIndex",
        component: () => import("../views/otherPage/innerIndex/index.vue"),
        meta: {
          title: "二级页面",
          keepAlive: true
        },

        // 首页
        // 关于米仓
        // 新闻中心
        // 社会责任
        // 人员招聘
        // 联系我们
        children: [
          {
            path: "/aboutRiceStroe",
            name: "aboutRiceStroe",
            component: () =>
              import(
                /* webpackChunkName: "Home" */ "../views/otherPage/aboutRiceStroe/index.vue"
              ),
            meta: {
              title: "关于米仓",
              keepAlive: true
            }
          },
          {
            path: "/coreBusiness",
            name: "coreBusiness",
            component: () =>
              import(
                /* webpackChunkName: "Home" */ "../views/otherPage/coreBusiness/index.vue"
              ),
            meta: {
              title: "核心业务",
              keepAlive: true
            }
          },
          {
            path: "/newCenter",
            name: "newCenter",
            component: () =>
              import(
                /* webpackChunkName: "Home" */ "../views/otherPage/newCenter/index.vue"
              ),
            meta: {
              title: "新闻中心",
              keepAlive: true
            }
          },
          {
            path: "/staffRecruit",
            name: "staffRecruit",
            component: () =>
              import(
                /* webpackChunkName: "Home" */ "../views/otherPage/staffRecruit/index.vue"
              ),
            meta: {
              title: "人员招聘",
              keepAlive: true
            }
          },
          {
            path: "/staffRecruitDetile",
            name: "staffRecruitDetile",
            component: () =>
              import(
                /* webpackChunkName: "Home" */ "../views/otherPage/staffRecruit/compnents/detile.vue"
              ),
            meta: {
              link: "/staffRecruit",
              father: "人员招聘",
              title: "详情",
              keepAlive: true
            }
          },
          {
            path: "/concactUs",
            name: "concactUs",
            component: () =>
              import(
                /* webpackChunkName: "Home" */ "../views/otherPage/concactUs/index.vue"
              ),
            meta: {
              title: "联系我们",
              keepAlive: true
            }
          },
          {
            path: "/newCenterDetil",
            name: "newCenterDetil",
            component: () =>
              import(
                /* webpackChunkName: "Home" */ "../views/otherPage/newCenter/components/newCenterDetil.vue"
              ),
            meta: {
              link: "/newCenter",
              father: "新闻中心",
              title: "文章详情",
              keepAlive: true
            }
          },
          {
            path: "/businessServe",
            name: "businessServe",
            component: () =>
              import(
                /* webpackChunkName: "Home" */ "../views/otherPage/businessServe/index.vue"
              ),
            meta: {
              title: "业务与服务",
              keepAlive: true
            },
          }, {
            path: '/businessServe/case',
            component: () => import('@/views/otherPage/businessServe/cases/index.vue'),
            meta: {
              title: '案例',
              keepAlive: false
            }
          },
          {
            path: "/dispatchSearch",
            name: "dispatchSearch",
            component: () =>
              import(
                    /* webpackChunkName: "Home" */ "../views/otherPage/dispatchSearch/index.vue"
              ),
            meta: {
              title: "业务与服务",
              keepAlive: true
            }
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

export default router;
