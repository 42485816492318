<template>
  <div id="app">
    <div class="home_container">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
}
.home_container {
  width: 100%;
  height: 100%;
}
</style>
